import axios from "axios"

const updateEmployee = async (id, formState, auth) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/employees/${id}`

  const response = await axios.put(
    endpoint,
    {
      name: formState.inputs.name.value,
      lastname: formState.inputs.lastname.value,
      email: formState.inputs.email.value,
      code: formState.inputs.code.value,
      company_id:
        auth.user.role !== "company"
          ? formState.inputs.company.value
          : auth.user.company.id,
      password: formState.inputs.password.value,
      repassword: formState.inputs.repassword.value,
    },
    {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    }
  )

  return response
}

export default updateEmployee
