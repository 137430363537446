import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutChart = ({ routes }) => {
  const statusCounts = [0, 0, 0, 0]

  routes.forEach((route) => {
    switch (route.status) {
      case "En ruta":
        statusCounts[0]++
        break
      case "Pendiente por iniciar":
        statusCounts[1]++
        break
      case "Terminado":
        statusCounts[2]++
        break
      case "Avería":
        statusCounts[3]++
        break
      default:
        break
    }
  })

  const data = {
    labels: ["En ruta", "Pendiente por iniciar", "Terminado", "Avería"],
    datasets: [
      {
        label: "Rutas",
        data: statusCounts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">Status</p>
        <button className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div className="card-content">
        <div className="content">
          <Doughnut data={data} />
        </div>
      </div>
    </div>
  )
}

export default DoughnutChart
