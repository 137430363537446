import fetchData from "shared/data/fetchData"

const fetchUser = async (id, auth, navigate, setError, setIsLoading) => {
  setIsLoading(true)

  try {
    const userData = await fetchData({
      endpoint: `users/${id}`,
      token: auth.token,
      dataKey: "user",
    })
    return userData // Return the fetched users
  } catch (err) {
    console.log(err)
    if (err.response?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.response?.data?.message || "Something went wrong")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchUser
