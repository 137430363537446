import React, { useMemo, useContext, useState } from "react"
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table"
import { mdiArrowDown, mdiArrowUp, mdiCircle } from "@mdi/js"
import { AuthContext } from "shared/context/auth-context"
import GlobalFilter from "./GlobalFilter"
import Icon from "@mdi/react"
import CheckBox from "./CheckBox"
import axios from "axios"

import "./Table.css"

const RowSelectTable = (props) => {
  const auth = useContext(AuthContext)
  const [ids, setIds] = useState([])
  const dataHeaders = props.dataHeaders
  const dataBody = props.dataBody

  const columns = useMemo(() => dataHeaders, [])

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state,
    setGlobalFilter,
    selectedFlatRows,
    state: {},
  } = useTable(
    {
      columns: columns,
      data: dataBody,
      initialState: { pageIndex: 0, pageSize: 100 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <CheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const { globalFilter } = state

  const getExport = () => {
    const data = selectedFlatRows.map((row) => row.original.id)
    console.log(data.join("-"))
    setIds(data.join("-"))
    window.open(
      `${process.env.REACT_APP_API_URL}/checkpoints/export/${data.join("-")}/7`
    )
  }

  return (
    <div>
      <div style={{ overflow: "hidden" }}>
        <button
          className="button is-success"
          style={{ float: "right" }}
          onClick={getExport}
        >
          Exportar
        </button>
      </div>
      <div className="columns">
        <div className="column">
          <label
            style={{
              fontWeight: "bold",
              display: "block",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            Buscar
          </label>
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            hasDateFilter={props.hasDateFilter}
          />
        </div>
      </div>
      <table className="table is-fullwidth" {...getTableProps()}>
        <thead>
          {headerGroups.map((head) => (
            <tr {...head.getHeaderGroupProps()}>
              {head.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className="sorted">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon path={mdiArrowDown} size={1} />
                      ) : (
                        <Icon path={mdiArrowUp} size={1} />
                      )
                    ) : (
                      <Icon path={mdiCircle} size={1} color={"#fff"} />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps({
                  onClick: (e) =>
                    props.onRowClicked &&
                    props.onRowClicked(row.cells[0].row.original, e),
                })}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      <pre>
        <code>{JSON.stringify(ids)}</code>
      </pre>
    </div>
  )
}

export default RowSelectTable
