import React, { useCallback, useState, useEffect, useContext } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"

import Sidebar from "shared/components/Sidebar"
import ContentBody from "./ContentBody"

import Login from "login/pages/Login"
import Reset from "login/pages/Reset"
import DoReset from "login/pages/DoReset"

import Dashboard from "dashboard/pages/Dashboard"

import Companies from "companies/pages/Companies"
import CreateCompany from "companies/pages/CreateCompany"
import EditCompany from "companies/pages/EditCompany"
import ViewCompany from "companies/pages/ViewCompany"

import Users from "users/pages/Users"
import CreateUser from "users/pages/CreateUser"
import EditUser from "users/pages/EditUser"

import RoutesPage from "routes/pages/Routes"
import CreateRoute from "routes/pages/CreateRoute"
import EditRoute from "routes/pages/EditRoute"
import EditRouteData from "routes/pages/EditRouteData"
import ViewRoute from "routes/pages/ViewRoute"

import Drivers from "drivers/pages/Drivers"
import CreateDriver from "drivers/pages/CreateDriver"
import EditDriver from "drivers/pages/EditDriver"

import Employees from "employees/pages/Employees"
import CreateEmployee from "employees/pages/CreateEmployee"
import EditEmployee from "employees/pages/EditEmployee"

import PrivacyPolicy from "policies/PrivacyPolicy"

import Load from "excel/pages/Load"
import Import from "excel/pages/Import"

const PrivateRoute = ({ element: Element }) => {
  const { isLoggedIn } = useContext(AuthContext)
  return isLoggedIn ? <Element /> : <Navigate to="/" replace />
}

const Main = () => {
  const [token, setToken] = useState(false)
  const [user, setUser] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const login = useCallback((user, token) => {
    setToken(token)
    setUser(user)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        user: user,
        token: token,
      })
    )
  }, [])

  const logout = useCallback(() => {
    setToken(false)
    setUser(null)
    localStorage.removeItem("userData")
  }, [])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"))
    if (storedData && storedData.token) {
      login(storedData.user, storedData.token)
    }
    setIsLoading(false)
  }, [login])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        user: user,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <ContentBody>
          {isLoading ? (
            <div>Cargando...</div>
          ) : (
            <>
              {token && <Sidebar />}
              <div className="main-content">
                <Routes>
                  <Route path="/" element={<Login />} />

                  {/* Privacy Policy */}
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                  {/* Dashboard */}
                  <Route
                    path="/panel"
                    element={<PrivateRoute element={Dashboard} />}
                  />

                  {/* User Management */}
                  <Route
                    path="/users"
                    element={<PrivateRoute element={Users} />}
                  />
                  <Route
                    path="/users/create"
                    element={<PrivateRoute element={CreateUser} />}
                  />
                  <Route
                    path="/users/edit/:id"
                    element={<PrivateRoute element={EditUser} />}
                  />

                  {/* Company Management */}
                  <Route
                    path="/companies"
                    element={<PrivateRoute element={Companies} />}
                  />
                  <Route
                    path="/companies/create"
                    element={<PrivateRoute element={CreateCompany} />}
                  />
                  <Route
                    path="/companies/edit/:id"
                    element={<PrivateRoute element={EditCompany} />}
                  />
                  <Route
                    path="/companies/view/:id"
                    element={<PrivateRoute element={ViewCompany} />}
                  />

                  {/* Route Management */}
                  <Route
                    path="/routes"
                    element={<PrivateRoute element={RoutesPage} />}
                  />
                  <Route
                    path="/routes/create"
                    element={<PrivateRoute element={CreateRoute} />}
                  />
                  <Route
                    path="/routes/edit/:id"
                    element={<PrivateRoute element={EditRoute} />}
                  />
                  <Route
                    path="/routes/edit/data/:id"
                    element={<PrivateRoute element={EditRouteData} />}
                  />
                  <Route
                    path="/routes/view/:id"
                    element={<PrivateRoute element={ViewRoute} />}
                  />

                  {/* Driver Management */}
                  <Route
                    path="/drivers"
                    element={<PrivateRoute element={Drivers} />}
                  />
                  <Route
                    path="/drivers/create"
                    element={<PrivateRoute element={CreateDriver} />}
                  />
                  <Route
                    path="/drivers/edit/:id"
                    element={<PrivateRoute element={EditDriver} />}
                  />

                  {/* Employee Management */}
                  <Route
                    path="/employees"
                    element={<PrivateRoute element={Employees} />}
                  />
                  <Route
                    path="/employees/create"
                    element={<PrivateRoute element={CreateEmployee} />}
                  />
                  <Route
                    path="/employees/edit/:id"
                    element={<PrivateRoute element={EditEmployee} />}
                  />

                  {/* Excel Operations */}
                  <Route
                    path="/load"
                    element={<PrivateRoute element={Import} />}
                  />
                  <Route
                    path="/import"
                    element={<PrivateRoute element={Load} />}
                  />

                  {/* Password Reset */}
                  <Route
                    path="/reset-password"
                    element={<PrivateRoute element={Reset} />}
                  />
                  <Route
                    path="/doreset/:token"
                    element={<PrivateRoute element={DoReset} />}
                  />
                </Routes>
              </div>
            </>
          )}
        </ContentBody>
      </Router>
    </AuthContext.Provider>
  )
}

export default Main
