import React from "react"

import "./Loader.css"

const Loader = (props) => {
  return (
    <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
      <div className="lds-dual-ring"></div>
    </div>
  )
}

export default Loader
