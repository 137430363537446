import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react"
import axios from "axios"
import { useForm } from "shared/hooks/formHook"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { VALIDATOR_REQUIRED } from "shared/utils/validator"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"
import GoogleMapReact from "google-map-react"
import update from "immutability-helper"
import SortItem from "shared/components/UIElements/SortItem"

const decodePoly = require("@mapbox/polyline")

const EditRoute = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const auth = useContext(AuthContext)

  const [route, setRoute] = useState(null)
  const markersMap = useRef([])
  const routePolyline = useRef(null)

  const [markers, setMarkers] = useState([])
  const [mapReference, setMapReference] = useState(null)
  const [mapsReference, setMapsReference] = useState(null)
  const [overview, setOverview] = useState("")
  const [center] = useState({ lat: 22.24, lng: -102.85 })
  const [isCalculated, setIsCalculated] = useState(true)
  const [legs, setLegs] = useState([])

  const [hourDeparture, setHourDeparture] = useState(8)
  const [minuteDeparture, setMinuteDeparture] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      company_id: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  useEffect(() => {
    document.title = "Editar Ruta"
    if (auth.token && mapReference) {
      fetchRoute()
    }
  }, [auth.token, mapsReference])

  /** set info server */
  const fetchRoute = async () => {
    try {
      setIsLoading(true)
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setRoute(response.data.route)
        setMarkers(response.data.route.Points)
        setOverview(response.data.route.overview)
        setHourDeparture(response.data.route.hour)
        setMinuteDeparture(response.data.route.minute)
        response.data.route.Points.forEach((point) => {
          console.log(point)
          let lat = parseFloat(point.lat)
          let lng = parseFloat(point.lng)
          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: mapReference,
            label: {
              color: "white",
              fontWeight: "bold",
              text: point.name || "N/A",
              className: "marker-label",
            },
            icon: {
              labelOrigin: new window.google.maps.Point(11, 50),
              size: new window.google.maps.Size(22, 40),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(11, 40),
            },
          })
          markersMap.current.push(marker)
        })

        const decodedPolyline = decodePoly.decode(response.data.route.overview)

        const latLngPoly = decodedPolyline.map((coord) => {
          return { lat: coord[0], lng: coord[1] }
        })

        routePolyline.current = new window.google.maps.Polyline({
          path: latLngPoly,
          geodesic: true,
          strokeColor: "#58b0b5",
          map: mapReference,
        })

        const bounds = new window.google.maps.LatLngBounds()
        for (var i = 0; i < routePolyline.current.getPath().getLength(); i++) {
          bounds.extend(routePolyline.current.getPath().getAt(i))
        }
        mapReference.fitBounds(bounds)
      }
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/", { replace: true })
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const updateRoute = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/${id}`,
        method: "PUT",
        data: {
          name: formState.inputs.name.value,
          points: markers,
          overview: overview,
          hour: hourDeparture,
          minute: minuteDeparture,
        },
      })

      if (response.status === 201) {
        navigate("/routes", { replace: true })
      } else {
        setError("Ocurrío un error inesperado")
      }
    } catch (err) {
      console.log(err)
      setError(err.response.data.message || "Ocurrío un error inesperado")
    }

    setIsLoading(false)
  }

  /** Markers manage */
  const addMarker = ({ x, y, lat, lng, event }) => {
    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      map: mapReference,
      label: {
        color: "white",
        fontWeight: "bold",
        text: `${markers.length + 1}`,
        className: "marker-label",
      },
      icon: {
        labelOrigin: new window.google.maps.Point(11, 50),
        size: new window.google.maps.Size(22, 40),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(11, 40),
      },
    })
    markersMap.current.push(marker)

    setMarkers((markers) => [...markers, { name: "", lat: lat, lng: lng }])
    if (routePolyline.current !== null) {
      routePolyline.current.setMap(null)
      routePolyline.current = null
    }
    setIsCalculated(false)
  }

  const removeMarker = (dataMarker, i) => {
    const filter = markers.filter((marker) => marker !== dataMarker)
    setMarkers(filter)

    markersMap.current[i].setMap(null)
    markersMap.current.splice(i, 1)
    if (routePolyline.current !== null) {
      routePolyline.current.setMap(null)
      routePolyline.current = null
    }
    setIsCalculated(false)
  }

  const clean = () => {
    setMarkers([])

    for (let m of markersMap.current) {
      m.setMap(null)
    }
    markersMap.current = []
    if (routePolyline.current !== null) {
      routePolyline.current.setMap(null)
      routePolyline.current = null
    }
    setIsCalculated(false)
    setLegs([])
  }

  const centerBounds = () => {
    const bounds = new mapsReference.LatLngBounds()
    markers.forEach((marker) => {
      bounds.extend(new mapsReference.LatLng(marker.lat, marker.lng))
    })
    mapReference.fitBounds(bounds)
  }

  const handleChangeName = (event, index) => {
    const items = [...markers]
    const item = { ...items[index] }
    item.name = event.target.value
    items[index] = item
    setMarkers(items)

    for (let m of markersMap.current) {
      m.setMap(null)
    }
    markersMap.current = []

    items.forEach((point) => {
      const lat = parseFloat(point.lat)
      const lng = parseFloat(point.lng)
      const marker = new window.google.maps.Marker({
        position: { lat, lng },
        map: mapReference,
        label: {
          color: "white",
          fontWeight: "bold",
          text: point.name,
          className: "marker-label",
        },
        icon: {
          labelOrigin: new window.google.maps.Point(11, 50),
          size: new window.google.maps.Size(22, 40),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(11, 40),
        },
      })
      markersMap.current.push(marker)
    })
  }

  /** set route directions */
  const handleDirections = () => {
    const directionsService = new mapsReference.DirectionsService()
    const directionsDisplay = new mapsReference.DirectionsRenderer()
    const waypts = []
    const last = markers[markers.length - 1]

    markers.forEach((marker, i) => {
      if (i !== 0 && i !== markers.length - 1) {
        waypts.push({
          location: `${marker.lat}, ${marker.lng}`,
          stopover: true,
        })
      }
    })

    directionsService.route(
      {
        origin: `${markers[0].lat}, ${markers[0].lng}`,
        destination: `${last.lat}, ${last.lng}`,
        waypoints: waypts,
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          directionsDisplay.setDirections(response)
          routePolyline.current = new mapsReference.Polyline({
            strokeColor: "#68a6d6",
            path: response.routes[0].overview_path,
          })
          routePolyline.current.setMap(mapReference)
          setOverview(response.routes[0].overview_polyline)
          setLegs(response.routes[0].legs)
          const minutes = response.routes[0].legs.map((leg) =>
            Math.ceil(leg.duration.value / 60)
          )
          const updatedMarkers = markers.map((marker, index) => ({
            ...marker,
            time: getSumTime(index, minutes),
          }))
          setMarkers(updatedMarkers)
        } else {
          window.alert("Directions request failed due to " + status)
        }
      }
    )
    setIsCalculated(true)
  }

  const importPoints = (e) => {
    clean()
    const points = e.target.value.split("/")
    points.forEach((point) => {
      if (point !== "") {
        const latLng = point.split(",")
        const lat = latLng[0]
        const lng = latLng[1]
        addMarker({ lat, lng })
      }
      if (points.length > 2) {
        const bounds = new mapsReference.LatLngBounds()
        markers.forEach((marker) => {
          bounds.extend(new mapsReference.LatLng(marker.lat, marker.lng))
        })
        mapReference.fitBounds(bounds)
      }
    })
  }

  const moveMarker = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = markers[dragIndex]
      setMarkers(
        update(markers, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      )

      if (routePolyline.current !== null) {
        routePolyline.current.setMap(null)
        routePolyline.current = null
      }
      setIsCalculated(false)
    },
    [markers]
  )

  function sumMinutes(hora, minutosASumar) {
    // Divide la hora y los minutos en partes separadas
    const [horaStr, minutosStr] = hora.split(":")

    // Convierte las partes en números enteros
    const horaInt = parseInt(horaStr)
    const minutosInt = parseInt(minutosStr)

    // Crea un objeto Date con la hora original
    const fechaHoraOriginal = new Date()
    fechaHoraOriginal.setHours(horaInt)
    fechaHoraOriginal.setMinutes(minutosInt)

    // Suma los minutos a la hora original
    const fechaHoraSumada = new Date(
      fechaHoraOriginal.getTime() - minutosASumar * 60000
    )

    // Obtiene la hora y minutos de la hora sumada
    const horaSumada = fechaHoraSumada.getHours()
    const minutosSumados = fechaHoraSumada.getMinutes()

    // Formatea la hora resultante en formato HH:mmAM/PM
    const amPm = horaSumada >= 12 ? "PM" : "AM"
    const horaFormateada = horaSumada % 12 || 12 // Convierte a formato de 12 horas
    const minutosFormateados = minutosSumados.toString().padStart(2, "0") // Asegura que los minutos sean dos dígitos

    return `${horaFormateada}:${minutosFormateados}${amPm}`
  }

  /** retorna el total sumado de minutos*/
  function getSumTime(index, arr) {
    // Verificar si el índice es válido
    if (index < 0 || index >= arr.length) {
      return 0
    }

    let sum = 0
    for (let i = index; i < arr.length; i++) {
      sum += arr[i]
    }

    return sum
  }

  return (
    <div>
      {isLoading && <Loader asOverlay />}
      {error && (
        <div className="columns">
          <div className="column">
            <div className="notification is-danger is-light">
              Error: {error}
            </div>
          </div>
        </div>
      )}
      <div className="columns">
        <div className="column">
          <div className="columns">
            {route && (
              <div style={{ width: "100%" }}>
                <div className="buttons are-small has-addons">
                  {markers.length > 1 && isCalculated && (
                    <button
                      className="button is-info"
                      type="button"
                      onClick={centerBounds}
                    >
                      Centrar
                    </button>
                  )}

                  <button
                    className="button is-warning"
                    type="button"
                    onClick={() => handleDirections()}
                  >
                    Recalcular
                  </button>
                  {isCalculated && (
                    <button
                      className="button is-success"
                      type="button"
                      onClick={updateRoute}
                    >
                      Actualizar
                    </button>
                  )}
                  {markers.length !== 0 && (
                    <button
                      className="button is-danger"
                      onClick={() => clean()}
                    >
                      Limpiar
                    </button>
                  )}
                </div>
                <DndProvider backend={HTML5Backend}>
                  {markers && markers.length > 0 && (
                    <div
                      style={{
                        height: "90vh",
                        padding: "10px 10px 30px 10px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      {markers.map((marker, index) => (
                        <SortItem
                          key={index}
                          index={index}
                          id={index}
                          moveCard={moveMarker}
                        >
                          <div className="columns" key={index}>
                            <div className="container-stop">
                              <div className="columns">
                                <div className="column">
                                  <div className="snap">
                                    <div className="snap-line-time">
                                      <b>Hora aproximada de llegada</b>{" "}
                                      {sumMinutes(
                                        `${hourDeparture}:${minuteDeparture}`,
                                        marker.time
                                      )}
                                    </div>
                                  </div>
                                  <input
                                    name={`name-${index}`}
                                    value={marker.name}
                                    className="input-stop"
                                    type="text"
                                    placeholder="Nombre del punto"
                                    onInput={(e) => handleChangeName(e, index)}
                                  />
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column">
                                  <input
                                    name={`lat-${index}`}
                                    className="input-stop"
                                    value={marker.lat}
                                    onChange={() => {}}
                                  />
                                </div>
                                <div className="column">
                                  <input
                                    name={`lng-${index}`}
                                    className="input-stop"
                                    value={marker.lng}
                                    onChange={() => {}}
                                  />
                                </div>
                                <div
                                  className="column is-one-fifth"
                                  onClick={() => removeMarker(marker, index)}
                                >
                                  <span className="delete">X</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SortItem>
                      ))}
                    </div>
                  )}
                </DndProvider>
              </div>
            )}
          </div>
        </div>
        <div className="column is-three-quarters">
          {error && <p className="notification is-danger is-light">{error}</p>}
          <div id="map">
            <h1
              style={{
                width: "49%",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              Editar Ruta - {route && route.name}
            </h1>
            <div className="columns">
              <div className="column">
                <div className="columns">
                  <div className="column">
                    <label>Hora</label>
                    <input
                      className="input-form-control"
                      placeholder="Hora"
                      value={hourDeparture}
                      onChange={(e) => setHourDeparture(e.target.value)}
                    />
                  </div>
                  <div className="column">
                    <label>Minuto</label>
                    <input
                      className="input-form-control"
                      placeholder="Minuto"
                      value={minuteDeparture}
                      onChange={(e) => setMinuteDeparture(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="column" style={{ paddingBottom: "0" }}>
                <div className="form-control" style={{ margin: "0" }}>
                  <label htmlFor="">Importar Ruta</label>
                  <textarea name="" id="" cols="30" onChange={importPoints} />
                </div>
              </div>
            </div>
            <div
              className="form-control"
              style={{
                width: "49%",
                display: "inline-block",
                marginBottom: "0",
              }}
            >
              {route && route.name && (
                <div>
                  <label htmlFor="">Nombre de la ruta </label>
                  <Input
                    type="text"
                    value={route.name}
                    id="name"
                    placeholder="Nombre"
                    validators={[VALIDATOR_REQUIRED()]}
                    onInput={inputHandler}
                    errorText="Este campo es obligatorio"
                  />
                </div>
              )}
            </div>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC11BhEN26L3kn-NIZrLZWuJ0ThQOp2dfs",
              }}
              center={center}
              zoom={6}
              yesIWantToUseGoogleMapApiInternals
              onClick={addMarker}
              onGoogleApiLoaded={({ map, maps }) => {
                setMapReference(map)
                setMapsReference(maps)
              }}
            ></GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditRoute
