import React from "react"

import "./PrivacyPolicy.css"

const PrivacyNotice = () => {
  return (
    <div
      className="container"
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        color: "#333",
      }}
    >
      <div
        style={{
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h1 style={{ textAlign: "center" }}>
          Aviso de Privacidad MyTrackingBus.com
        </h1>
        <p>
          En <strong>MyTrackingBus</strong>, respetamos y protegemos la
          privacidad de nuestros usuarios. Este Aviso de Privacidad explica cómo
          recopilamos, usamos y protegemos la información personal que usted nos
          proporciona al usar nuestra aplicación.
        </p>
        <section>
          <h2>1. Responsable del Tratamiento de Datos</h2>
          <p>
            <strong>MyTrackingBus</strong>, con domicilio en Cda Fresnillo 134,
            Col. San José, México, es el responsable del tratamiento de sus
            datos personales. Si tiene alguna pregunta sobre este Aviso de
            Privacidad, puede contactarnos en{" "}
            <a href="mailto:privacidad@mytrackingbus.com">
              privacidad@mytrackingbus.com
            </a>
            .
          </p>
        </section>
        <section>
          <h2>2. Datos Personales que Recopilamos</h2>
          <p>Los datos personales que recopilamos incluyen:</p>
          <ul style={{ paddingLeft: "20px" }}>
            <li>Nombre completo</li>
            <li>Número de teléfono</li>
            <li>Dirección de origen y destino</li>
            <li>Ubicación en tiempo real</li>
            <li>Correo electrónico</li>
          </ul>
        </section>
        <section>
          <h2>3. Finalidad del Tratamiento de Datos</h2>
          <p>Los datos recopilados son utilizados para:</p>
          <ul style={{ paddingLeft: "20px" }}>
            <li>Proporcionar el servicio de transporte solicitado.</li>
            <li>Monitorear el trayecto de los autobuses en tiempo real.</li>
            <li>Garantizar la seguridad de los usuarios durante el viaje.</li>
            <li>Comunicar actualizaciones importantes sobre el servicio.</li>
          </ul>
        </section>
        <section>
          <h2>4. Transferencia de Datos</h2>
          <p>
            Sus datos personales no serán compartidos con terceros sin su
            consentimiento, salvo en los casos previstos por la ley.
          </p>
        </section>
        <section>
          <h2>5. Derechos del Usuario</h2>
          <p>
            Usted tiene derecho a acceder, rectificar, cancelar u oponerse al
            tratamiento de sus datos personales (Derechos ARCO). Para ejercer
            estos derechos, puede contactarnos en{" "}
            <a href="mailto:contacto@mytrackingbus.com">
              contacto@mytrackingbus.com
            </a>
            .
          </p>
        </section>
        <section>
          <h2>6. Seguridad de los Datos</h2>
          <p>
            Implementamos medidas técnicas, administrativas y físicas para
            proteger sus datos personales contra acceso no autorizado, pérdida o
            alteración.
          </p>
        </section>
        <section>
          <h2>7. Cambios al Aviso de Privacidad</h2>
          <p>
            Nos reservamos el derecho de modificar este Aviso de Privacidad en
            cualquier momento. Cualquier cambio será notificado a través de
            nuestra aplicación.
          </p>
        </section>
        <p style={{ textAlign: "center", marginTop: "20px" }}>
          Al usar nuestra aplicación, usted acepta este Aviso de Privacidad y el
          tratamiento de sus datos personales conforme a los términos aquí
          descritos.
        </p>
      </div>
    </div>
  )
}

export default PrivacyNotice
