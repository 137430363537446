import React, { useContext, useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { VALIDATOR_REQUIRED } from "shared/utils/validator"
import { useForm } from "shared/hooks/formHook"
import { AuthContext } from "shared/context/auth-context"
import fetchBusinessUsers from "shared/data/users/fetchBusinessUsers"
import fetchCompany from "shared/data/companies/fetchCompany"
import Input from "shared/components/FormElements/Input"
import ImageUpload from "shared/components/FormElements/ImageUpload"
import axios from "axios"
import Loader from "shared/components/UIElements/Loader"
import ErrorHandler from "shared/components/ErrorHandler"

const EditCompany = () => {
  const { id } = useParams()
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [company, setCompany] = useState(null)
  const [businessUsers, setBusinessUsers] = useState([])
  const [error, setError] = useState()
  const [errors, setErrors] = useState([])
  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      image: {
        value: "",
        isValid: false,
      },
      userParent: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  useEffect(() => {
    document.title = "Editar Empresa"

    if (!auth.token) {
      return
    }

    if (auth.user.role === "company") {
      navigate("/panel", { replace: true })
    } else if (auth.user.role === "admin") {
      const loadBusinessUsers = async () => {
        const users = await fetchBusinessUsers(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setBusinessUsers(users)
      }

      loadBusinessUsers()
    }

    const loadCompany = async () => {
      const companyData = await fetchCompany(
        id,
        auth,
        navigate,
        setError,
        setIsLoading
      )

      if (companyData) {
        setCompany(companyData)
        setFormData(
          {
            name: {
              value: companyData.name,
              isValid: true,
            },
            userParent: {
              value: companyData.userParent,
              isValid: true,
            },
            image: {
              value: companyData.image,
              isValid: true,
            },
          },
          true
        )
      }
    }

    loadCompany()
  }, [id, auth, navigate, setFormData])

  const handleError = (e) => {
    const errorMessages = e.response?.data.errors || ["An error occurred"]
    setErrors(errorMessages)
  }

  const companyUpdate = async () => {
    setIsLoading(true)

    try {
      const formData = new FormData()
      formData.append("name", formState.inputs.name.value)
      formData.append("user_parent_id", formState.inputs.userParent.value)
      formData.append("image", formState.inputs.image.value)

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/companies/${company.id}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            ContentType: "application/json",
          },
        }
      )

      if (response.status === 200) {
        navigate("/companies", { replace: true })
      }
    } catch (e) {
      if (e.response?.status === 401) {
        navigate("/", { replace: true })
      } else {
        handleError(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      {isLoading && <Loader asOverlay />}
      <h1 className="title">Editar Empresa</h1>
      <div className="actions buttons">
        <button className="button is-success" onClick={companyUpdate}>
          Actualizar
        </button>
        <button className="button is-danger" onClick={() => navigate(-1)}>
          Cancelar
        </button>
      </div>
      {error && <div className="notification is-danger is-light">{error}</div>}
      <ErrorHandler errors={errors} />
      {company && (
        <div className="card-mibus">
          <div className="columns">
            <div className="column">
              <div className="notification is-primary is-light">
                <b>ID de compañía: </b>
                {company.id}
              </div>
              <div className="form-group">
                <Input
                  label="Nombre"
                  type="text"
                  element="text"
                  id="name"
                  value={company.name}
                  placeholder="Nombre de la compañía"
                  validators={[VALIDATOR_REQUIRED()]}
                  errorText="El nombre de la compañía es obligatorio"
                  onInput={inputHandler}
                />
              </div>
              {auth && auth.user.role === "admin" && (
                <div className="form-group">
                  <Input
                    label="Usuario Encargado"
                    type="select"
                    element="select"
                    id="userParent"
                    value={company.userParent}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="El usuario encargado es obligatorio"
                    onInput={inputHandler}
                  >
                    <option value="">Seleccione un usuario</option>
                    {businessUsers.map((businessUser) => (
                      <option key={businessUser.id} value={businessUser.id}>
                        {businessUser.name}
                      </option>
                    ))}
                  </Input>
                </div>
              )}
              {company && company.image && (
                <img
                  src={`${process.env.REACT_APP_URL}/${company.image}`}
                  alt=""
                />
              )}
            </div>
            <div className="column">
              <ImageUpload
                center
                id="image"
                onInput={inputHandler}
                errorText="Selecciona una imagen"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EditCompany
