import fetchData from "shared/data/fetchData"

const fetchBusinessUsers = async (auth, navigate, setError, setIsLoading) => {
  setIsLoading(true)

  const endpoint = "users/type/business"

  try {
    const users = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "users",
    })

    return users
  } catch (err) {
    if (err.response?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.response?.data?.message || "Something went wrong")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchBusinessUsers
