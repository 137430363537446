import axios from "axios"

const insertEmployee = async (
  formState,
  auth,
  navigate,
  setError,
  setIsLoading
) => {
  setIsLoading(true)

  const endpoint = `${process.env.REACT_APP_API_URL}/employees/`

  try {
    const response = await axios.post(
      endpoint,
      {
        author: auth.user.id,
        name: formState.inputs.name.value,
        lastname: formState.inputs.lastname.value,
        email: formState.inputs.email.value,
        code: formState.inputs.code.value,
        company_id: formState.inputs.company.value,
        password: formState.inputs.password.value,
        repassword: formState.inputs.repassword.value,
      },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )

    setIsLoading(false)

    if (response.status === 201) {
      navigate("/employees", { replace: true })
    }
  } catch (err) {
    setIsLoading(false)
    setError(err.response?.data?.message || "Algo salió mal")
  }
}

export default insertEmployee
