import axios from "axios"

const fetchData = async ({ endpoint, token, dataKey, mapper }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.status === 200 && response.data && response.data[dataKey]) {
      // Apply the mapper function if provided, otherwise return the raw data
      return mapper
        ? response.data[dataKey].map(mapper)
        : response.data[dataKey]
    } else {
      throw new Error("Unexpected response format")
    }
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error)
    throw error
  }
}

export default fetchData
