import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import { VALIDATOR_REQUIRED } from "shared/utils/validator"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import axios from "axios"
import Loader from "shared/components/UIElements/Loader"
import Input from "shared/components/FormElements/Input"
import GoogleMapReact from "google-map-react"
import update from "immutability-helper"

import "./Map.css"
import SortItem from "shared/components/UIElements/SortItem"

const Map = () => {
  const navigate = useNavigate()
  const markersMap = useRef([])
  const routePolyline = useRef(null)

  const auth = useContext(AuthContext)

  const [markers, setMarkers] = useState([])
  const [mapReference, setMapReference] = useState(null)
  const [mapsReference, setMapsReference] = useState(null)
  const [overview, setOverview] = useState("")
  const [center, setCenter] = useState({ lat: 22.24, lng: -102.85 })
  const [companies, setCompanies] = useState([])
  const [isCalculated, setIsCalculated] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      company_id: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  const [hourDeparture, setHourDeparture] = useState(8)
  const [minuteDeparture, setMinuteDeparture] = useState(0)
  const [legs, setLegs] = useState([])

  useEffect(() => {
    if (!auth.token) {
      return
    }
    getCompanies()
    console.log("markers", markers)
  }, [auth.token])

  const addMarker = ({ x, y, lat, lng, event }) => {
    const marker = new window.google.maps.Marker({
      position: { lat: parseFloat(lat), lng: parseFloat(lng) },
      map: mapReference,
      label: {
        color: "white",
        fontWeight: "bold",
        text: `${markers.length + 1}`,
        className: "marker-label",
      },
      icon: {
        labelOrigin: new window.google.maps.Point(11, 50),
        size: new window.google.maps.Size(22, 40),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(11, 40),
      },
    })
    markersMap.current.push(marker)

    setMarkers((markers) => [
      ...markers,
      {
        name: `${markers.length + 1}`,
        lat: lat,
        lng: lng,
      },
    ])

    if (routePolyline.current !== null) {
      routePolyline.current.setMap(null)
      routePolyline.current = null
    }
    setIsCalculated(false)
  }

  const clean = () => {
    setMarkers([])

    for (let m of markersMap.current) {
      m.setMap(null)
    }
    markersMap.current = []
    if (routePolyline.current !== null) {
      routePolyline.current.setMap(null)
      routePolyline.current = null
    }
    setIsCalculated(false)
    setLegs([])
    setHourDeparture(8)
    setMinuteDeparture(0)
  }

  const removeMarker = (dataMarker, i) => {
    const filter = markers.filter((marker) => marker !== dataMarker)
    setMarkers(filter)

    markersMap.current[i].setMap(null)
    markersMap.current.splice(i, 1)
    if (routePolyline.current !== null) {
      routePolyline.current.setMap(null)
      routePolyline.current = null
    }
    setIsCalculated(false)
  }

  const handleDirections = () => {
    const horaPartida = new Date()
    horaPartida.setDate(horaPartida.getDate() + 1)
    horaPartida.setHours(hourDeparture)
    horaPartida.setMinutes(minuteDeparture)

    const directionsService = new mapsReference.DirectionsService()
    const directionsDisplay = new mapsReference.DirectionsRenderer()
    const waypts = []
    const last = markers[markers.length - 1]

    markers.forEach((marker, i) => {
      if (i !== 0 && i !== markers.length - 1) {
        waypts.push({
          location: `${marker.lat}, ${marker.lng}`,
          stopover: true,
        })
      }
    })

    directionsService.route(
      {
        origin: `${markers[0].lat}, ${markers[0].lng}`,
        destination: `${last.lat}, ${last.lng}`,
        waypoints: waypts,
        travelMode: "DRIVING",
        drivingOptions: {
          departureTime: horaPartida, // Departure time
          trafficModel: "pessimistic", // or "optimistic", "bestguest"
        },
      },
      (response, status) => {
        if (status === "OK") {
          directionsDisplay.setDirections(response)
          routePolyline.current = new mapsReference.Polyline({
            strokeColor: "#68a6d6",
            path: response.routes[0].overview_path,
          })
          routePolyline.current.setMap(mapReference)
          setOverview(response.routes[0].overview_polyline)
          setLegs(response.routes[0].legs)
          const minutes = response.routes[0].legs.map((leg) =>
            Math.ceil(leg.duration.value / 60)
          )
          const updatedMarkers = markers.map((marker, index) => ({
            ...marker,
            time: getSumTime(index, minutes),
          }))
          setMarkers(updatedMarkers)
        } else {
          window.alert("Directions request failed due to " + status)
        }
      }
    )
    setIsCalculated(true)
  }

  const importPoints = (e) => {
    clean()
    const points = e.target.value.split("/")
    points.forEach((point) => {
      if (point !== "") {
        const latLng = point.split(",")
        const lat = latLng[0]
        const lng = latLng[1]
        addMarker({ lat, lng })
      }
      if (points.length > 2) {
        const bounds = new mapsReference.LatLngBounds()
        markers.forEach((marker) => {
          bounds.extend(new mapsReference.LatLng(marker.lat, marker.lng))
        })
        mapReference.fitBounds(bounds)
      }
    })
  }

  /** form methods */
  const handleChange = (event, index) => {
    let items = [...markers]
    let item = { ...items[index] }
    item.name = event.target.value
    items[index] = item
    setMarkers(items)

    for (let m of markersMap.current) {
      m.setMap(null)
    }
    markersMap.current = []

    items.forEach((point) => {
      const lat = parseFloat(point.lat)
      const lng = parseFloat(point.lng)
      const marker = new window.google.maps.Marker({
        position: { lat, lng },
        map: mapReference,
        label: {
          color: "white",
          fontWeight: "bold",
          text: point.name,
          className: "marker-label",
        },
        icon: {
          labelOrigin: new window.google.maps.Point(11, 50),
          size: new window.google.maps.Size(22, 40),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(11, 40),
        },
      })
      markersMap.current.push(marker)
    })
  }

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: url,
        method: "GET",
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/", { replace: true })
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const saveRoute = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes`,
        method: "POST",
        data: {
          name: formState.inputs.name.value,
          company_id: formState.inputs.company.value,
          points: markers,
          overview: overview,
          hour: hourDeparture,
          minute: minuteDeparture,
        },
      })

      setIsLoading(false)

      if (response.status === 201) {
        navigate("/routes", { replace: true })
      } else {
        setError("Algo salió mal")
      }
    } catch (err) {
      setIsLoading(false)
      setError(err.response.data.message || "Algo salió mal")
    }
  }

  const moveMarker = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = markers[dragIndex]
      setMarkers(
        update(markers, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      )
      if (routePolyline.current !== null) {
        routePolyline.current.setMap(null)
        routePolyline.current = null
      }
      setIsCalculated(false)
    },
    [markers]
  )

  function sumMinutes(hora, minutosASumar) {
    // Divide la hora y los minutos en partes separadas
    const [horaStr, minutosStr] = hora.split(":")

    // Convierte las partes en números enteros
    const horaInt = parseInt(horaStr)
    const minutosInt = parseInt(minutosStr)

    // Crea un objeto Date con la hora original
    const fechaHoraOriginal = new Date()
    fechaHoraOriginal.setHours(horaInt)
    fechaHoraOriginal.setMinutes(minutosInt)

    // Suma los minutos a la hora original
    const fechaHoraSumada = new Date(
      fechaHoraOriginal.getTime() - minutosASumar * 60000
    )

    // Obtiene la hora y minutos de la hora sumada
    const horaSumada = fechaHoraSumada.getHours()
    const minutosSumados = fechaHoraSumada.getMinutes()

    // Formatea la hora resultante en formato HH:mmAM/PM
    const amPm = horaSumada >= 12 ? "PM" : "AM"
    const horaFormateada = horaSumada % 12 || 12 // Convierte a formato de 12 horas
    const minutosFormateados = minutosSumados.toString().padStart(2, "0") // Asegura que los minutos sean dos dígitos

    return `${horaFormateada}:${minutosFormateados}${amPm}`
  }

  /** retorna el total sumado de minutos*/
  function getSumTime(index, arr) {
    // Verificar si el índice es válido
    if (index < 0 || index >= arr.length) {
      return 0
    }

    let sum = 0
    for (let i = index; i < arr.length; i++) {
      sum += arr[i]
    }

    return sum
  }

  function getNumber(number, type) {
    const options = []

    for (let i = 0; i < number; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }

    return (
      <select
        className="form-control"
        defaultValue={type === "hour" ? hourDeparture : minuteDeparture}
        onChange={(e) => {
          if (type === "hour") {
            setHourDeparture(e.target.value)
          } else {
            setMinuteDeparture(e.target.value)
          }
        }}
      >
        {options}
      </select>
    )
  }

  return (
    <>
      {isLoading && <Loader asOverlay />}
      {error && (
        <div className="columns">
          <div className="column">
            <div className="notification is-danger is-light">
              Error: {error}
            </div>
          </div>
        </div>
      )}
      <div className="columns">
        <div className="column">
          <div className="columns">
            <div style={{ width: "100%" }}>
              <div className="buttons has-addons">
                {markers.length !== 0 && (
                  <>
                    <button
                      className="button is-danger"
                      onClick={() => clean()}
                    >
                      Limpiar
                    </button>
                    {!isCalculated && (
                      <button
                        className="button is-warning"
                        onClick={handleDirections}
                      >
                        Calcular
                      </button>
                    )}
                    {isCalculated && (
                      <button className="button is-success" onClick={saveRoute}>
                        Registrar
                      </button>
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  height: "85vh",
                  padding: "10px 10px 30px 0px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <DndProvider backend={HTML5Backend}>
                  {markers.length > 0 &&
                    markers.map((marker, index) => (
                      <SortItem
                        key={index}
                        index={index}
                        id={index}
                        moveCard={moveMarker}
                      >
                        {legs.length > 0 && legs[index] && (
                          <div className="snap">
                            <div className="snap-line-start">
                              <b>Desde:</b>{" "}
                              {legs[index].start_address.substring(0, 200)}
                            </div>
                            <div className="snap-line-end">
                              <b>Hasta:</b>{" "}
                              {legs[index].end_address.substring(0, 200)}
                            </div>
                            <div className="snap-line-time">
                              <b>Tiempo:</b> {marker.time} min <br />
                              <b>Distancia:</b> {legs[index].distance.text}{" "}
                              <br />
                              <b>Hora aproximada de llegada: </b>
                              {sumMinutes(
                                `${hourDeparture}:${minuteDeparture}`,
                                marker.time
                              )}
                            </div>
                          </div>
                        )}
                        {markers.length - 1 === index ? (
                          <div className="snap-line">
                            <b>
                              {`Hora de llegada :
                              ${hourDeparture.toString().padStart(2, "0")} :
                              ${minuteDeparture.toString().padStart(2, "0")}`}
                            </b>
                          </div>
                        ) : null}
                        <div className="container-stop">
                          <div className="columns">
                            <div className="column">
                              <label htmlFor="">Nombre del punto: </label>
                              <input
                                name={`name-${index}`}
                                className="input-stop"
                                type="text"
                                placeholder="Nombre del punto"
                                value={marker.name}
                                onInput={(e) => handleChange(e, index)}
                              />
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <input
                                name={`lat-${index}`}
                                className="input-stop"
                                value={marker.lat}
                                onChange={() => {}}
                              />
                            </div>
                            <div className="column">
                              <input
                                name={`lng-${index}`}
                                className="input-stop"
                                value={marker.lng}
                                onChange={() => {}}
                              />
                            </div>
                            <div
                              className="column is-one-fifth"
                              onClick={() => removeMarker(marker, index)}
                            >
                              <span className="delete">X</span>
                            </div>
                          </div>
                        </div>
                      </SortItem>
                    ))}
                </DndProvider>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-three-quarters">
          <div className="mark">
            <div className="code">{/*overview*/}</div>
          </div>
          <div className="columns">
            <div className="column">
              <label htmlFor="">Hora de llegada</label>
              <div className="columns">
                <div className="column">{getNumber(24, "hour")}</div>
                <div className="column">{getNumber(60, "minute")}</div>
              </div>
            </div>
            <div
              className="column"
              style={{ paddingTop: "0", paddingBottom: "0" }}
            >
              <div className="form-control" style={{ margin: "0" }}>
                <label htmlFor="">Importar Ruta</label>
                <textarea name="" id="" cols="30" onChange={importPoints} />
              </div>
            </div>
          </div>
          <div className="columns">
            <div
              className="column"
              style={{ paddingTop: "0", paddingBottom: "0" }}
            >
              <Input
                type="text"
                id="name"
                placeholder="Nombre"
                label="Nombre de la ruta"
                validators={[VALIDATOR_REQUIRED()]}
                onInput={inputHandler}
                errorText="Este campo es obligatorio"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault()
                }}
              />
            </div>
            {companies && (
              <div
                className="column"
                style={{ paddingTop: "0", paddingBottom: "0" }}
              >
                <Input
                  id="company"
                  placeholder="Ruta"
                  label="Empresa"
                  className="form-control"
                  element="select"
                  onInput={inputHandler}
                  validators={[VALIDATOR_REQUIRED()]}
                >
                  <option value="">Seleccionar</option>
                  {companies.map((company) => {
                    return (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    )
                  })}
                </Input>
              </div>
            )}
          </div>
          <div id="mapCreate">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC11BhEN26L3kn-NIZrLZWuJ0ThQOp2dfs",
              }}
              center={center}
              zoom={6}
              yesIWantToUseGoogleMapApiInternals
              onClick={addMarker}
              onGoogleApiLoaded={({ map, maps }) => {
                setMapReference(map)
                setMapsReference(maps)
              }}
            ></GoogleMapReact>
          </div>
        </div>
      </div>
    </>
  )
}

export default Map
