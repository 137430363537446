import React from "react"
import Table from "shared/components/DataTable/Table"

const DashTable = ({ routes }) => {
  const headers = [
    {
      Header: "Nombre",
      accessor: "name",
    },
    {
      Header: "Costo Ruta",
      accessor: "cost",
    },
    {
      Header: "Capacidad",
      accessor: "capacity",
    },
    {
      id: "availables",
      Header: "Libres",
      Cell: (props) => (
        <>{props.row.original.capacity - props.row.original.used}</>
      ),
    },
    {
      Header: "Ocupación",
      accessor: "used",
      Cell: (row) => <>{row.value && row.value}</>,
    },
    {
      Header: "%",
      Cell: (props) => (
        <>{(props.row.original.used / props.row.original.capacity) * 100}</>
      ),
    },
    {
      Header: "Estado",
      accessor: "status",
    },
    {
      Header: "Acciones",
      Cell: (props) => <>{props.row.original.id}</>,
    },
  ]

  return (
    <div>
      <div>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Rutas</p>
            <button className="card-header-icon" aria-label="more options">
              <span className="icon">
                <i className="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </header>
          <div className="card-content">
            <div className="content">
              <Table
                dataHeaders={headers}
                dataBody={routes}
                hasDateFilter={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashTable
