import React, { useEffect } from "react"

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  useEffect(() => {
    const hashPage = parseInt(window.location.hash.replace("#page-", ""), 10)

    if (!isNaN(hashPage) && hashPage !== currentPage) {
      handlePageChange(hashPage)
    }
  }, [currentPage, handlePageChange])

  const renderPageNumbers = () => {
    const pageNumbers = []

    if (totalPages <= 7) {
      for (let page = 1; page <= totalPages; page++) {
        pageNumbers.push(
          <li key={page}>
            <a
              href={`#page-${page}`}
              className={`pagination-link ${
                page === currentPage ? "is-current" : ""
              }`}
              onClick={() => handlePageChange(page)}
              disabled={page === currentPage}
            >
              {page}
            </a>
          </li>
        )
      }
    } else {
      const ellipsis = <span key="ellipsis">...</span>

      pageNumbers.push(
        <li key={1}>
          <a
            href={`#page-1`}
            className={`pagination-link ${
              1 === currentPage ? "is-current" : ""
            }`}
            onClick={() => handlePageChange(1)}
            disabled={1 === currentPage}
          >
            1
          </a>
        </li>
      )

      const start = Math.max(2, currentPage - 1)
      const end = Math.min(totalPages - 1, currentPage + 1)

      if (start > 2) {
        pageNumbers.push(<li key="ellipsis-start">{ellipsis}</li>)
      }

      for (let page = start; page <= end; page++) {
        pageNumbers.push(
          <li key={page}>
            <a
              href={`#page-${page}`}
              className={`pagination-link ${
                page === currentPage ? "is-current" : ""
              }`}
              onClick={() => handlePageChange(page)}
              disabled={page === currentPage}
            >
              {page}
            </a>
          </li>
        )
      }

      if (end < totalPages - 1) {
        pageNumbers.push(<li key="ellipsis-end">{ellipsis}</li>)
      }

      pageNumbers.push(
        <li key={totalPages}>
          <a
            href={`#page-${totalPages}`}
            className={`pagination-link ${
              totalPages === currentPage ? "is-current" : ""
            }`}
            onClick={() => handlePageChange(totalPages)}
            disabled={totalPages === currentPage}
          >
            {totalPages}
          </a>
        </li>
      )
    }

    return pageNumbers
  }

  return (
    <div>
      {totalPages > 1 && (
        <div style={{ marginTop: "15px" }}>
          <nav className="pagination" role="navigation" aria-label="pagination">
            <ul className="pagination-list">{renderPageNumbers()}</ul>
          </nav>
        </div>
      )}
    </div>
  )
}

export default Pagination
