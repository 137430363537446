import fetchData from "shared/data/fetchData"

const fetchDrivers = async (auth, navigate, setError, setIsLoading) => {
  setIsLoading(true)

  let endpoint = ""

  if (auth.user.role === "company") {
    endpoint = `drivers/company/${auth.user.company.id}`
  } else if (auth.user.role === "admin") {
    endpoint = "drivers"
  }

  try {
    const drivers = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "drivers",
    })

    return drivers // Return the fetched drivers
  } catch (err) {
    if (err.response?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.response?.data?.message || "Something went wrong")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchDrivers
