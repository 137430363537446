import React, { useReducer, useEffect } from "react"
import { validate } from "shared/utils/validator"
import Icon from "@mdi/react"

import "./Input.css"
import { mdiEye } from "@mdi/js"

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      }
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
        value: action.val,
        isValid: validate(action.val, action.validators),
      }
    default:
      return state
  }
}

const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.value || "",
    isTouched: false,
    isValid: props.valid || false,
  })

  const { id, onInput } = props
  const { value, isValid } = inputState

  useEffect(() => {
    props.onInput(props.id, inputState.value, inputState.isValid)
  }, [id, value, isValid, onInput])

  const changeHandler = (e) => {
    dispatch({
      type: "CHANGE",
      val: e.target.value,
      validators: props.validators,
    })
    if (props.callFunction !== undefined) {
      props.callFunction(e.target.value)
    }
  }

  const touchHandler = (e) => {
    dispatch({
      type: "TOUCH",
      val: e.target.value,
      validators: props.validators,
    })
  }

  const revealPassword = () => {
    let password = document.getElementById(props.id)
    let eye = document.getElementById(props.id + "-eye")
    if (password.type === "password") {
      password.type = "text"
      eye.classList.add("active")
    } else {
      password.type = "password"
      eye.classList.remove("active")
    }
  }

  const element = () => {
    switch (props.element) {
      case "textarea":
        return (
          <textarea
            id={props.id}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={inputState.value}
          />
        )
      case "select":
        return (
          <select
            id={props.id}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={inputState.value}
          >
            {props.children}
          </select>
        )
      case "password":
        return (
          <div>
            <div className="control has-icons-left has-icons-right">
              <input
                id={props.id}
                type="password"
                placeholder={props.placeholder}
                onChange={changeHandler}
                onBlur={touchHandler}
                value={inputState.value}
              />
              <span
                id={`${props.id}-eye`}
                className="icon is-small is-right"
                onClick={revealPassword}
              >
                <Icon path={mdiEye} size={1} />
              </span>
            </div>
          </div>
        )
      default:
        return (
          <input
            id={props.id}
            type={props.type}
            placeholder={props.placeholder}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={inputState.value}
          />
        )
    }
  }

  return (
    <div
      className={`form-control ${
        !inputState.isValid && inputState.isTouched && "form-control--invalid"
      }`}
    >
      <label htmlFor={props.id}>{props.label}</label>
      {element()}
      {!inputState.isValid && inputState.isTouched && (
        <span className="error">{props.errorText}</span>
      )}
    </div>
  )
}

export default Input
