import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { VALIDATOR_REQUIRED } from "shared/utils/validator"
import { useForm } from "shared/hooks/formHook"
import { AuthContext } from "shared/context/auth-context"
import fetchBusinessUsers from "shared/data/users/fetchBusinessUsers"
import Input from "shared/components/FormElements/Input"
import ImageUpload from "shared/components/FormElements/ImageUpload"
import axios from "axios"
import Loader from "shared/components/UIElements/Loader"

const CreateCompany = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [businessUsers, setBusinessUsers] = useState([])
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      image: {
        value: "",
        isValid: false,
      },
      userParent: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  useEffect(() => {
    document.title = "Registrar Empresa"

    if (!auth.token) {
      return
    }

    if (auth.user.role === "company") {
      navigate("/panel", { replace: true })
    } else if (auth.user.role === "admin") {
      const loadBusinessUsers = async () => {
        const users = await fetchBusinessUsers(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setBusinessUsers(users)
      }

      loadBusinessUsers()
    }
  }, [auth, navigate, setError, setIsLoading])

  const companySubmitHandler = async () => {
    setIsLoading(true)

    try {
      const formData = new FormData()
      formData.append("name", formState.inputs.name.value)
      formData.append("author", auth.user.id)
      formData.append(
        "userParent",
        auth.user.role === "admin"
          ? formState.inputs.userParent.value
          : auth.user.id
      )
      formData.append("image", formState.inputs.image.value)

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/companies`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
          },
        }
      )

      if (response.status === 200) {
        navigate("/companies", { replace: true })
      }
    } catch (e) {
      if (e.response?.status === 401) {
        navigate("/", { replace: true })
      } else {
        setError(e.response?.data.message || "An error occurred")
      }
    }
    setIsLoading(false)
  }

  return (
    <div>
      <h1 className="title">Registrar Empresa</h1>
      {isLoading && <Loader asOverlay />}
      <div className="actions">
        <button className="button" onClick={companySubmitHandler}>
          Registrar
        </button>
        <button className="button is-danger" onClick={() => navigate(-1)}>
          Cancelar
        </button>
      </div>
      <div className="card-mibus">
        {error && (
          <div className="columns">
            <div className="column">
              <div className="notification is-danger is-light">{error}</div>
            </div>
          </div>
        )}
        <div className="columns">
          <div className="column">
            <div className="form-group">
              <Input
                label="Nombre"
                type="text"
                element="text"
                id="name"
                placeholder="Nombre de la compañía"
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El nombre de la compañía es obligatorio"
                onInput={inputHandler}
              />
            </div>
            {auth.user.role === "admin" && (
              <div className="form-group">
                <Input
                  label="Usuario Encargado"
                  type="select"
                  element="select"
                  id="userParent"
                  validators={[VALIDATOR_REQUIRED()]}
                  errorText="El usuario encargado es obligatorio"
                  onInput={inputHandler}
                >
                  <option value="">Seleccione un usuario</option>
                  {businessUsers.map((businessUser) => (
                    <option key={businessUser.id} value={businessUser.id}>
                      {businessUser.name}
                    </option>
                  ))}
                </Input>
              </div>
            )}
          </div>
          <div className="column">
            <ImageUpload
              center
              id="image"
              onInput={inputHandler}
              errorText="Selecciona una imagen"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCompany
