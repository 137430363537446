import fetchData from "shared/data/fetchData"
import fetchEmployees from "./fetchEmployees"

const fetchEmployeesByCompany = async (
  companyId,
  auth,
  navigate,
  setError,
  setIsLoading
) => {
  if (companyId === "all") {
    return await fetchEmployees(auth, navigate, setError, setIsLoading)
  }

  setIsLoading(true)

  const endpoint = `employees/company/${companyId}`

  try {
    const employees = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "employees",
    })

    return employees
  } catch (err) {
    if (err.employees?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.employees?.data?.message || "An error occurred.")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchEmployeesByCompany
