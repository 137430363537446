import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { mdiBookEdit, mdiDelete } from "@mdi/js"
import fetchCompanies from "shared/data/companies/fetchCompanies"
import fetchDrivers from "shared/data/drivers/fetchDrivers"
import fetchDriversByCompany from "shared/data/drivers/fetchDriversByCompany"
import deactivateDriver from "shared/data/drivers/deactivateDriver"
import Table from "shared/components/DataTable/Table"
import Icon from "@mdi/react"
import Loader from "shared/components/UIElements/Loader"

const Drivers = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [drivers, setDrivers] = useState([])
  const [companies, setCompanies] = useState([])
  const [showModal, setShowModal] = useState("")
  const [isLoading, setIsLoading] = useState(null)
  const [driverId, setUserId] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Conductores"
    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      const loadCompanies = async () => {
        const fetchedCompanies = await fetchCompanies(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setCompanies(fetchedCompanies)
      }
      loadCompanies()
    }

    if (auth.user.role !== "business") {
      const loadDrivers = async () => {
        const fetchedDrivers = await fetchDrivers(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setDrivers(fetchedDrivers)
      }
      loadDrivers()
    }
  }, [auth, navigate, setError, setIsLoading])

  const loadDriversByCompany = async (companyId) => {
    const fetchedDrivers = await fetchDriversByCompany(
      companyId,
      auth,
      navigate,
      setError,
      setIsLoading
    )
    setDrivers(fetchedDrivers)
  }

  const handleDeactivateDriver = async (id) => {
    closeModal()
    setUserId(null)
    await deactivateDriver(
      id,
      auth,
      navigate,
      setError,
      setIsLoading,
      fetchDrivers
    )
  }

  const openModal = (id) => {
    setShowModal("is-active")
    setUserId(id)
    setError(null)
  }

  const closeModal = () => {
    setShowModal("")
  }

  const headers = [
    {
      Header: "Nombre(s)",
      accessor: "name",
    },
    {
      Header: "Apellidos",
      accessor: "lastname",
    },
    {
      Header: "Correo",
      accessor: "email",
    },
    {
      Header: "Matrícula",
      accessor: "registration",
    },
    {
      Header: "Licencia",
      accessor: "licenseType",
    },
    {
      Header: "Compañía",
      accessor: "company.name",
    },
    {
      Header: "",
      accessor: "id",
      Cell: (row) => (
        <>
          <NavLink to={`/drivers/edit/${row.value}`}>
            <Icon path={mdiBookEdit} size={1} onClick={() => {}}>
              Edit
            </Icon>
          </NavLink>
          <Icon
            className="deactivate"
            path={mdiDelete}
            size={1}
            onClick={() => {
              openModal(row.value)
            }}
          >
            Delete
          </Icon>
        </>
      ),
    },
  ]

  return (
    <div>
      <h1 className="title">Conductores</h1>
      <div className="actions">
        {auth && auth.user.role !== "company" && (
          <NavLink to="/drivers/create">
            <button className="button is-normal">Registrar Conductor</button>
          </NavLink>
        )}
      </div>
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="card-mibus">
          {error && (
            <div className="columns">
              <div className="column">
                <div className="notification is-danger is-light">
                  Error: {error}
                </div>
              </div>
            </div>
          )}
          <Table
            dataCompanies={
              auth.user && auth.user.role !== "company" ? companies : null
            }
            cb={loadDriversByCompany}
            dataHeaders={headers}
            dataBody={drivers}
          />
        </div>
      </div>

      <div className={`modal ${showModal}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirmar</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => closeModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              Confirma que deseas eliminar este conductor, esta acción solo
              puede revertirla un administrador.
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-danger"
                onClick={() => handleDeactivateDriver(driverId)}
              >
                Eliminar Conductor
              </button>
              <button className="button" onClick={() => closeModal()}>
                Cancelar
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Drivers
