import React, { useContext, useState, useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import {
  VALIDATOR_REQUIRED,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "shared/utils/validator"
import { useForm } from "shared/hooks/formHook"
import { AuthContext } from "shared/context/auth-context"
import fetchCompanies from "shared/data/companies/fetchCompanies"
import insertEmployee from "shared/data/employees/insertEmployee"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"

const CreateEmployee = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  useEffect(() => {
    document.title = "Registrar Empleado"

    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      const loadCompanies = async () => {
        const fetchedCompanies = await fetchCompanies(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setCompanies(fetchedCompanies)
      }
      loadCompanies()
    }
  }, [auth, navigate, setError, setIsLoading])

  const isFormValid = useCallback(() => {
    for (const inputId in formState.inputs) {
      if (formState.inputs[inputId].isValid === false) {
        return false
      }
    }
    return true
  }, [formState.inputs])

  const submitHandler = async (event) => {
    event.preventDefault()
    await insertEmployee(formState, auth, navigate, setError, setIsLoading)
  }

  return (
    <div>
      <div>
        <h1 className="title">Registrar Empleado</h1>
        <div className="actions">
          <button
            className="button is-success"
            disabled={!isFormValid()}
            onClick={submitHandler}
          >
            Registrar
          </button>
          <button onClick={() => navigate(-1)} className="button is-danger">
            Cancelar
          </button>
        </div>
      </div>
      <div className="card-mibus">
        {isLoading && <Loader asOverlay />}
        <div className="columns">
          <div className="column">
            {error && (
              <div className="columns">
                <div className="column">
                  <div className="notification is-danger is-light">
                    Error: {error}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {companies && (
              <div className="form-group">
                <Input
                  id="company"
                  placeholder="Ruta"
                  label="Empresa"
                  className="form-control"
                  element="select"
                  onInput={inputHandler}
                  validators={[VALIDATOR_REQUIRED()]}
                  errorText="La empresa es obligatoria"
                >
                  {auth.user.role !== "company" && (
                    <>
                      <option value="">Seleccionar</option>
                      {companies.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </>
                  )}
                  {auth.user.role === "company" && (
                    <option value={auth.user.company.id}>
                      {auth.user.company.name}
                    </option>
                  )}
                </Input>
              </div>
            )}
            <div className="form-group">
              <Input
                type="text"
                id="name"
                label="Nombre"
                placeholder="Nombre"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El nombre es obligatorio"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="lastname"
                label="Apellido"
                placeholder="Apellido"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El apellido es obligatorio"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="code"
                placeholder="Matrícula"
                label="Matrícula"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="La matrícula es obligatoria"
              />
            </div>
          </div>
          <div className="column">
            <div className="form-group">
              <Input
                type="text"
                id="email"
                placeholder="Correo electrónico"
                label="Correo electrónico"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                errorText="El correo electrónico es obligatorio y debe ser válido"
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                id="password"
                element="password"
                placeholder="Contraseña"
                label="Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_MINLENGTH(8)]}
                errorText="La contraseña es obligatoria y debe tener al menos 8 caracteres"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="repassword"
                element="password"
                placeholder="Confirmar Contraseña"
                label="Confirmar Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="Confirma la contraseña"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateEmployee
