import fetchData from "shared/data/fetchData"

const fetchCompany = async (id, auth, navigate, setError, setIsLoading) => {
  setIsLoading(true)

  const endpoint = `companies/${id}`

  try {
    const company = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "company",
    })

    return company
  } catch (err) {
    if (err.response?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.response?.data?.message || "An error occurred")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchCompany
