import fetchData from "shared/data/fetchData"

const fetchRoutes = async (
  auth,
  navigate,
  setError,
  setIsLoading,
  companyId
) => {
  setIsLoading(true)

  const endpoint = `routes/company/${companyId}`

  try {
    const routes = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "routes",
    })

    return routes
  } catch (err) {
    if (err.response && err.response.status === 401) {
      navigate("/")
    } else {
      setError(err.response?.data?.message || "Something went wrong")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchRoutes
