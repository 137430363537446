import fetchData from "shared/data/fetchData"

const fetchDriver = async (id, auth, navigate, setError, setIsLoading) => {
  setIsLoading(true)

  try {
    const driverData = await fetchData({
      endpoint: `drivers/${id}`,
      token: auth.token,
      dataKey: "driver",
    })

    return driverData
  } catch (err) {
    if (err.response?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.response?.data?.message || "An error occurred")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchDriver
