import axios from "axios"

const deactivateDriver = async (
  id,
  auth,
  navigate,
  setError,
  setIsLoading,
  fetchDrivers
) => {
  setIsLoading(true)

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/drivers/${id}`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )

    if (response.status === 200) {
      await fetchDrivers(auth, navigate, setError, setIsLoading) // Fetch drivers after successful deletion
    } else {
      setError(
        response.data?.error ||
          "An error occurred while deactivating the driver."
      )
    }
  } catch (err) {
    setError(err.response?.data?.message || "An error occurred.")
  } finally {
    setIsLoading(false)
  }
}

export default deactivateDriver
