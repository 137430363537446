import fetchData from "shared/data/fetchData"

const fetchEmployees = async (auth, navigate, setError, setIsLoading) => {
  setIsLoading(true)

  let endpoint = ""

  if (auth.user.role === "company") {
    endpoint = `employees/company/${auth.user.company.id}`
  } else if (auth.user.role === "admin") {
    endpoint = "employees"
  }

  try {
    const employees = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "employees",
    })

    return employees
  } catch (err) {
    if (err.response?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.response?.data?.message || "Something went wrong")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchEmployees
