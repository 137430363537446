import React, { useState, useContext, useEffect, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import { VALIDATOR_REQUIRED, VALIDATOR_EMAIL } from "shared/utils/validator"
import fetchCompanies from "shared/data/companies/fetchCompanies"
import fetchEmployee from "shared/data/employees/fetchEmployee"
import updateEmployee from "shared/data/employees/updateEmployee"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"
import ErrorHandler from "shared/components/ErrorHandler"

const EditEmployee = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [companies, setCompanies] = useState([])
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState(null)
  const [formState, inputHandler, setFormData] = useForm(
    {
      company: {
        value: employee ? employee.Company.id : "",
        isValid: true,
      },
      name: {
        value: employee ? employee.name : "",
        isValid: true,
      },
      lastname: {
        value: employee ? employee.lastname : "",
        isValid: true,
      },
      code: {
        value: employee ? employee.code : "",
        isValid: true,
      },
      email: {
        value: employee ? employee.email : "",
        isValid: true,
      },
      password: {
        value: "",
        isValid: true,
      },
      repassword: {
        value: "",
        isValid: true,
      },
    },
    false
  )

  useEffect(() => {
    if (employee) {
      setFormData(
        {
          company: {
            value: employee.Company.id,
            isValid: true,
          },
          name: {
            value: employee.name,
            isValid: true,
          },
          lastname: {
            value: employee.lastname,
            isValid: true,
          },
          code: {
            value: employee.code,
            isValid: true,
          },
          email: {
            value: employee.email,
            isValid: true,
          },
          password: {
            value: "",
            isValid: true,
          },
          repassword: {
            value: "",
            isValid: true,
          },
        },
        true
      )
    }
  }, [employee, setFormData])

  useEffect(() => {
    document.title = "Editar Empleado"
    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      const loadCompanies = async () => {
        const fetchedCompanies = await fetchCompanies(
          auth,
          navigate,
          setError,
          setIsLoading
        )
        setCompanies(fetchedCompanies)
      }
      loadCompanies()
    }

    const loadEmployee = async () => {
      const fetchedEmployee = await fetchEmployee(
        id,
        auth,
        navigate,
        setError,
        setIsLoading
      )
      setEmployee(fetchedEmployee)
    }
    loadEmployee()
  }, [id, auth, navigate, setError, setIsLoading])

  const handleError = (e) => {
    const errorMessages = e.response?.data.errors || ["An error occurred"]
    setErrors(errorMessages)
  }

  const submitHandler = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await updateEmployee(id, formState, auth)
      console.log(response)
      if (response.status === 201) {
        navigate("/employees", { replace: true })
      } else {
        handleError({ response })
      }
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const isFormValid = useCallback(() => {
    for (const inputId in formState.inputs) {
      if (formState.inputs[inputId].isValid === false) {
        return false
      }
    }
    return true
  }, [formState.inputs])

  return (
    <div>
      <div>
        <h1 className="title">Editar Empleado</h1>
        <div className="actions buttons">
          <button
            className="button is-success"
            disabled={!isFormValid()}
            onClick={submitHandler}
          >
            Actualizar
          </button>
          <button onClick={() => navigate(-1)} className="button is-danger">
            Cancelar
          </button>
        </div>
      </div>
      {error && (
        <div className="notification is-danger is-light">Error: {error}</div>
      )}
      <ErrorHandler errors={errors} />
      <div className="card-mibus">
        {isLoading && <Loader asOverlay />}
        {employee && (
          <div className="columns">
            <div className="column">
              {companies &&
                auth.user &&
                auth.user.role !== "company" &&
                (employee.company !== null ? (
                  <div className="form-group">
                    <div style={{ display: "none" }}>
                      {JSON.stringify(employee.Company)}
                    </div>
                    <Input
                      id="company"
                      placeholder="Empresa"
                      label="Empresa"
                      className="form-control"
                      value={employee.Company.id}
                      element="select"
                      onInput={inputHandler}
                      validators={[]}
                    >
                      <option value="">Seleccionar</option>
                      {companies.map((company) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        )
                      })}
                    </Input>
                  </div>
                ) : (
                  <div className="form-group">
                    <Input
                      id="company"
                      placeholder="Ruta"
                      label="Empresa"
                      className="form-control"
                      element="select"
                      onInput={inputHandler}
                      validators={[]}
                    >
                      <option value="">Seleccionar</option>
                      {companies.map((company) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        )
                      })}
                    </Input>
                  </div>
                ))}
              <div className="form-group">
                <Input
                  type="text"
                  id="name"
                  label="Nombre"
                  value={employee.name}
                  placeholder="Nombre"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El nombre es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="text"
                  id="lastname"
                  label="Apellido"
                  value={employee.lastname}
                  placeholder="Apellido"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El apellido es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="text"
                  id="code"
                  value={employee.code}
                  placeholder="Matrícula"
                  label="Matrícula"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El  es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
            </div>
            <div className="column">
              <div className="form-group">
                <Input
                  type="text"
                  id="email"
                  value={employee.email}
                  placeholder="Correo electrónico"
                  label="Correo electrónico"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="Debes colocar un email válido"
                  validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  id="password"
                  element="password"
                  placeholder="Contraseña (Solo llenar para cambiar la anterior)"
                  label="Contraseña (Solo llenar para cambiar la anterior)"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="La contraseña debe contener al menos 8 caracteres"
                  validators={[]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  id="repassword"
                  element="password"
                  placeholder="Confirmar Contraseña"
                  label="Confirmar Contraseña"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="La contraseña debe contener al menos 8 caracteres"
                  validators={[]}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditEmployee
