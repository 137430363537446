import fetchData from "shared/data/fetchData"

const fetchCompanies = async (auth) => {
  const endpoint =
    auth.user.role === "admin"
      ? "companies"
      : `companies/business/${auth.user.id}`

  const companies = await fetchData({
    endpoint,
    token: auth.token,
    dataKey: "companies",
  })

  return companies
}

export default fetchCompanies
