import { useContext, useState, useEffect, useCallback } from "react"
import axios from "axios"
import logo from "logo.png"
import { NavLink, useNavigate } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRED,
} from "shared/utils/validator"
import { useForm } from "shared/hooks/formHook"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"

import "./Login.css"

const Login = () => {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  // Redirect to /panel if the user is already logged in
  useEffect(() => {
    document.title = "Iniciar Sesión"
    if (auth.token) {
      navigate("/panel", { replace: true })
    }
  }, [auth.token, navigate])

  const authSubmitHandler = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login/`,
        {
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }
      )

      if (response.status === 200) {
        auth.login(response.data.user, response.data.token)
        navigate("/panel", { replace: true })
      } else {
        setError("Algo salió mal")
      }
    } catch (err) {
      setError(err.response?.data?.message || "Algo salió mal")
    } finally {
      setIsLoading(false)
    }
  }

  const handleInput = useCallback(
    (id, value, isValid) => {
      inputHandler(id, value, isValid)
    },
    [inputHandler]
  )

  return (
    <div className="wrapper-login">
      {isLoading && <Loader asOverlay />}
      <div className="sidebar-login">
        <img src={logo} alt="" className="logo" />
        <h1 className="logo-title">My Tracking Bus</h1>
        {error && <div className="notification is-danger">{error}</div>}
        <form onSubmit={authSubmitHandler}>
          <Input
            id="email"
            type="email"
            placeholder="Correo"
            validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
            errorText="El correo electrónico no es válido"
            onInput={handleInput}
          />
          <Input
            id="password"
            type="password"
            element="password"
            placeholder="Contraseña (+8 caracteres)"
            validators={[VALIDATOR_MINLENGTH(8)]}
            errorText="Este campo es obligatorio y requiere al menos 8 caractéres"
            onInput={handleInput}
          />
          <input
            type="submit"
            className="button is-normal"
            value="Entrar"
            disabled={!formState.isValid}
          />
          <div className="links-help">
            <NavLink to="/reset-password">Perdí mi contraseña</NavLink>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
