import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function BarChart({ routes }) {
  const options = {
    plugins: {
      title: {
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  const labels = routes.map((route) => route.name)
  const capacities = routes.map((route) => route.capacity)
  const usedValues = routes.map((route) => route.used)
  const availables = capacities.map(
    (capacity, index) => capacity - usedValues[index]
  )

  const data = {
    labels,
    datasets: [
      {
        label: "Capacidad",
        data: capacities,
        backgroundColor: "rgb(255, 99, 132)",
        stack: "Stack 0",
      },
      {
        label: "Ocupacion",
        data: usedValues,
        backgroundColor: "rgb(255, 205, 86)",
        stack: "Stack 1",
      },
      {
        label: "Disponibles",
        data: availables,
        backgroundColor: "rgb(75, 192, 192)",
        stack: "Stack 1",
      },
    ],
  }

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">Ocupación de rutas</p>
        <button className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div className="card-content">
        <div className="content">
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  )
}

export default BarChart
