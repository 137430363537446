import fetchData from "shared/data/fetchData"

const fetchEmployee = async (id, auth, navigate, setError, setIsLoading) => {
  setIsLoading(true)

  const endpoint = `employees/${id}`

  try {
    const employee = await fetchData({
      endpoint,
      token: auth.token,
      dataKey: "employee",
    })

    return employee
  } catch (err) {
    if (err.response?.status === 401) {
      navigate("/", { replace: true })
    } else {
      setError(err.response?.data?.message || "An error occurred")
    }
  } finally {
    setIsLoading(false)
  }
}

export default fetchEmployee
