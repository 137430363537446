import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import fetchCompanies from "../data/companies/fetchCompanies"

const useFetchCompanies = (auth) => {
  const navigate = useNavigate()
  const [companies, setCompanies] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const loadCompanies = async () => {
      setIsLoading(true)
      try {
        const fetchedCompanies = await fetchCompanies(auth)
        setCompanies(fetchedCompanies)
      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/", { replace: true })
        } else {
          setError(err.response?.data?.message || "Something went wrong")
        }
      } finally {
        setIsLoading(false)
      }
    }

    if (auth.token && auth.user.role !== "company") {
      loadCompanies()
    } else if (auth.user.role === "company") {
      navigate("/panel", { replace: true })
    }
  }, [auth, navigate])

  return { companies, isLoading, error }
}

export default useFetchCompanies
